import React, { useState } from 'react';
import { Link } from 'react-router-dom';
const Footer = () => {
  return (
    <footer className="bg-black text-white pt-8">
      <div className="container mx-auto py-6 flex flex-col items-start">
        <h2 className="text-xl sm:text-2xl md:text-3xl lg:text-[25px] mb-4 leading-tight">
          {/* <span className="md:block">We are ready,</span>
          <span className="md:block">Let's Connect</span> */}
          <span className='max-md:ml-6'>We are ready, Let's Connect</span>
        </h2>
        {/* <div className="flex space-x-4 mb-4">
          <a href="#" aria-label="Instagram">
            <img src="https://multimedia.shreepremcosmetics.com/spc-website/Instagram.svg" alt="Instagram" className="h-8 w-8" />
          </a>
          <a href="#" aria-label="Facebook">
            <img src="https://multimedia.shreepremcosmetics.com/spc-website/Facebook.svg" alt="Facebook" className="h-8 w-8" />
          </a>
          <a href="#" aria-label="WhatsApp">
            <img src="https://multimedia.shreepremcosmetics.com/spc-website/WhatsApp.svg" alt="WhatsApp" className="h-8 w-8" />
          </a>
        </div> */}
        <div className="text-left mb-4 space-y-1 max-md:ml-6">
          <p>Email: info.premcosmetics@gmail.com</p>
          <p>Call us: 95391888888</p>
        </div>
      </div>
      <div className="bg-red-500 py-4 flex justify-center">
  <div className="w-full max-w-[1280px] flex items-center justify-between">
    <Link to={'/terms'} className="flex items-center space-x-4">
      <p className="cursor-pointer text-base sm:text-lg md:text-xl lg:text-lg max-md:ml-6">
        Terms and Conditions
      </p>
    </Link>
    <p className="text-base sm:text-lg md:text-xl lg:text-lg max-md:mr-6">
      © SHREEPREMCOSMETICS
    </p>
  </div>
</div>
    </footer>
  );
};
export default Footer;