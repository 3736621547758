import BeautyPage from "./BeautyPage";
import OurStory from "./OurStory";
import VisitStore from "./VisitStore";
import Footer from './Footer'
import Header from './Header'
const Main = () => {
  return (
    <>
          <Header/>
          <BeautyPage />
          <OurStory />
          <VisitStore />
          <Footer />
    </>
  );
};

export default Main;
