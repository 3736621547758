import React, { useRef, useState } from "react";

const OurStory = () => {
  const videoRef = useRef(null);
  const [isMuted, setIsMuted] = useState(true);

  const handleMuteToggle = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className="bg-[#F5EAEA] py-10 px-8 flex overflow-hidden">
      <div className="max-w-7xl w-full flex flex-col lg:flex-col items-start">
        {/* Text Section */}
        <div className="lg:w-1/3 w-full lg:pr-8 mb-16 max-md:mb-6">
          <h2 className="text-5xl text-left ml-12 font-semibold text-gray-800 max-md:text-center max-md:mr-10">
            Our Story
          </h2>
        </div>
        {/* Video and Description Section */}
        <div className="lg:w-1/1 max-md:w-[650px] w-full flex max-md:flex-col relative mt-12 lg:mt-0 lg:ml-12 mb-14 ">
          <div className="relative w-[60%]">
            <div className="absolute bg-red-500 w-[500px] h-[299px] max-md:w-[250px] max-md:mr-12 max-md:h-[150px] max-md:-bottom-20 max-md:-right-10 lg:w-[500px] lg:h-[299px] -right-10 -bottom-8 z-0 "></div>
            <div className="w-[100%] max-md:h-[150px] max-md:w-[400px] max-md:pr-10 h-[300px] lg:w-[100%] lg:h-[300px] relative z-10 left-1">
              <video
                ref={videoRef}
                width="100%"
                height="100%"
                src="https://multimedia.shreepremcosmetics.com/spc-website/001.mp4" // Path to your local video
                autoPlay
                loop
                muted={isMuted}
                controls={false}
                style={{ objectFit: "cover" }}
              ></video>
              {/* Mute/Unmute Button */}
              <button
                onClick={handleMuteToggle}
                className="absolute -bottom-20 max-md:-bottom-10 max-md:mr-8 right-4 bg-gray-700 text-white p-3 rounded-full z-20"
              >
                {isMuted ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      d="M8 2.75v10.5a.751.751 0 0 1-1.238.57L3.473 11H1.75A1.75 1.75 0 0 1 0 9.25v-2.5C0 5.784.784 5 1.75 5h1.722l3.29-2.82A.75.75 0 0 1 8 2.75m3.28 2.47L13 6.94l1.72-1.72a.75.75 0 0 1 1.042.018a.75.75 0 0 1 .018 1.042L14.06 8l1.72 1.72a.749.749 0 0 1-.326 1.275a.75.75 0 0 1-.734-.215L13 9.06l-1.72 1.72a.749.749 0 0 1-1.275-.326a.75.75 0 0 1 .215-.734L11.94 8l-1.72-1.72a.749.749 0 0 1 .326-1.275a.75.75 0 0 1 .734.215m-7.042 1.1a.75.75 0 0 1-.488.18h-2a.25.25 0 0 0-.25.25v2.5c0 .138.112.25.25.25h2c.179 0 .352.064.488.18L6.5 11.62V4.38Z"
                    />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fill="currentColor"
                      d="M7.563 2.069A.75.75 0 0 1 8 2.75v10.5a.751.751 0 0 1-1.238.57L3.472 11H1.75A1.75 1.75 0 0 1 0 9.25v-2.5C0 5.784.784 5 1.75 5h1.723l3.289-2.82a.75.75 0 0 1 .801-.111M6.5 4.38L4.238 6.319a.75.75 0 0 1-.488.181h-2a.25.25 0 0 0-.25.25v2.5c0 .138.112.25.25.25h2c.179 0 .352.064.488.18L6.5 11.62Zm6.096-2.038a.75.75 0 0 1 1.06 0a8 8 0 0 1 0 11.314a.75.75 0 0 1-1.042-.018a.75.75 0 0 1-.018-1.042a6.5 6.5 0 0 0 0-9.193a.75.75 0 0 1 0-1.06Zm-1.06 2.121l-.001.001a5 5 0 0 1 0 7.07a.749.749 0 0 1-1.275-.326a.75.75 0 0 1 .215-.734a3.5 3.5 0 0 0 0-4.95a.75.75 0 1 1 1.061-1.061"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
          <p className="text-left text-gray-600 text-lg mt-64 ml-20 w-[40%] italic max-md:w-[50%] max-md:mt-32 max-md:ml-6">
            Our journey started in 2000 when we worked in a 300 sq ft area and
            today we are working in multiple locations in 30,000 sq ft. The
            concept of selling items at wholesale rates was brought to Indore by
            us. We have a wide range of more than 10,000 Cosmetics and Grocery
            products.
          </p>
        </div>
      </div>
    </div>
  );
};

export default OurStory;
