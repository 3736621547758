import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import Main from './components/Main'
import TermsAndConditions from './components/terms-and-conditions';

function App() {
  return (
    <Router>
        <Routes>
        <Route path="/" element={<Main/>} />
       <Route path="/terms" element={<TermsAndConditions />} />
       </Routes>
    </Router>
  );
}

export default App;
