import React from 'react';
import Header from './Header'; // Adjust the import path as needed
import termsData from '../data/terms.json';
import Footer from './Footer';
import { useEffect } from 'react';

const TermsAndConditions = () => {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div className='flex flex-col min-h-screen'>
      {/* Render the Header component */}
      <Header />

      {/* Rest of the Terms and Conditions content */}
      <div className="flex-grow lg:w-[100%] lg:mt-16 max-md:mt-16 inset-0 top-[60px] bg-gray-800 bg-opacity-50 flex items-center justify-center z-40">
        <div className="w-full h-full bg-white p-8 border border-gray-300 rounded shadow-lg overflow-auto">
          {/* Adding padding to shift the content inside the container */}
          <div className="lg:pl-8 max-md:pl-4">
            <h1 className="text-3xl font-bold mb-4 text-black">{termsData.title}</h1>
            {termsData.content.map((section, index) => (
              <div key={index} className="mb-4">
                <h2 className="text-xl font-semibold text-black">{section.heading}</h2>
                <p
                  className="text-gray-700 mt-2"
                  dangerouslySetInnerHTML={{
                    __html: section.text.replace(/\n/g, '<br />'),
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
