import React from "react";
const BeautyPage = () => {
  return (
    <div className="bg-[#F5EAEA] min-h-[900px] flex flex-col items-center p-4 lg:pt-40 md:p-8">
      {/* Main Content */}
      <main className="flex flex-col-reverse lg:flex-row items-center justify-around px-4 md:px-8 w-full max-w-8xl gap-6 md:gap-10 mt-16 md:mt-20 lg:mt-8">
        {/* Text Content */}
        <div className="flex flex-col max-md:mt-10 items-start text-left w-full lg:mt-0 pl-0">
          <h1 className="text-2xl lg:text-[65px] max-md:text-[40px] font-semibold text-gray-800 leading-snug break-words">
            Step into a world where beauty knows no bounds
          </h1>
          <p className="text-lg text-gray-600 mt-4 max-md:mr-24 break-words">
            Get Best brands at best prices
          </p>
          {/* Call to Action */}
          <div className="flex flex-col max-md:mt-24 mt-8 lg:ml-3 ">
            <h2 className="text-md max-md:text-xl max-md:font-semibold lg:mt-6 text-center font-large text-red-500">
              App Coming Soon
            </h2>
            <div className="flex flex-wrap items-center space-x-4 ">
              {/* <img
                src="https://multimedia.shreepremcosmetics.com/spc-website/playstore.svg"
                alt="Google Play"
                className="h-6 md:h-8 lg:h-10"
              />
              <img
                src="https://multimedia.shreepremcosmetics.com/spc-website/appstore.svg"
                alt="App Store"
                className="h-6 md:h-8 lg:h-10"
              /> */}
            </div>
            <p className="text-gray-500 text-lg text-center">or</p>
            <button className="bg-transparent text-gray-700 border border-black py-2 px-4 rounded-md mt-4 lg:w-[220px] max-md:w-[350px] shadow-sm">
              Visit your nearest Store
            </button>
          </div>
        </div>
        {/* Image Grid */}
        <div className="w-full max-md:pr-4 max-md:w-[400px] mt-14 lg:mt-0">
          <div className="grid grid-cols-1 max-md:mt-14  lg:w-[800px] max-md:grid-cols-3 sm:grid-cols-3 max-md:gap-2 gap-6">
            <img
              src="https://multimedia.shreepremcosmetics.com/spc-website/home1.png"
              alt="Makeup"
              className="w-full h-auto max-w-full md:max-w-sm lg:max-w-md shadow-lg"
            />
            <img
              src="https://multimedia.shreepremcosmetics.com/spc-website/home2.png"
              alt="Flowers"
              className="w-full h-auto max-w-full md:max-w-sm lg:max-w-md shadow-lg max-md:-translate-y-12 sm:transform sm:-translate-y-16"
            />
            <img
              src="https://multimedia.shreepremcosmetics.com/spc-website/home3.png"
              alt="Lipstick"
              className="w-full h-auto max-w-full md:max-w-sm lg:max-w-md shadow-lg"
            />
          </div>
        </div>
      </main>
    </div>
  );
};
export default BeautyPage;