import React from 'react';

const Header = () => {
  return (
    <header className="bg-white w-full flex justify-between items-center max-md:pl-0 border-b-2 border-gray-200 fixed top-0 left-0 px-8 py-4 shadow-md z-50">
  <div className="flex items-center">
    <img src="https://multimedia.shreepremcosmetics.com/spc-website/logo.svg" alt="Shree Prem Cosmetics" className="h-10 ml-10" />
  </div>
</header>
  );
};

export default Header;
