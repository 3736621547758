import React from 'react';

const VisitStore = () => {
  return (
    <div className="bg-[#f5eaea] py-20 px-8 flex justify-center ">
      <div className="max-w-7xl w-full flex flex-col lg:flex-row items-center">
        {/* Text Section */}
        <div className="lg:w-1/3 w-full text-left ">
          <h2 className="text-5xl font-semibold text-gray-800 leading-tight">
            <span className="block">Visit your</span>
            <span className="block">nearest store</span>
          </h2>
          <p className="text-lg text-gray-600 mt-4 sm:block">
            <span className="block italic">Enhanced shopping</span>
            <span className="block italic">experience at the store</span>
          </p>
        </div>

        {/* Google Map Embed Section */}
        <div className="lg:w-2/3 w-full mt-12 lg:mt-10">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d59304.3073126522!2d75.76810522673671!3d22.694154610794907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3962fd2eafb7e1c3%3A0x9264ab5aa4037230!2sShree%20Prem%20Cosmetics!5e0!3m2!1sen!2sin!4v1724495258614!5m2!1sen!2sin"
            className="w-full h-[450px] border-0"
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default VisitStore;
